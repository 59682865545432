import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LayoutManager from 'erpcore/utils/LayoutManager';
import { hasPermission, getPermissionsAccessForEntities } from 'erpcore/utils/RolesManager';
import { useSelector } from 'react-redux';
import { getMeData } from 'erpcore/utils/AuthManager/AuthManager.selectors';
import SuspenseLoader from 'erpcore/components/SuspenseLoader';

// Screens / Pages
// System
const Error404 = React.lazy(() => import('erpcore/screens/Error404'));
// General
const ResetPasswordSignedIn = React.lazy(() =>
    import('erpcore/screens/ForgotPassword/screens/ResetPasswordSignedIn')
);
const DashboardRouter = React.lazy(() => import('erpcore/screens/Dashboard/DashboardRouter.jsx'));
const Dashboards = React.lazy(() => import('erpcore/screens/Dashboards'));
const ProjectReviewDashboard = React.lazy(() => import('erpcore/screens/ProjectReviewDashboard'));
const SetActiveDashboard = React.lazy(() =>
    import('erpcore/screens/Dashboards/components/SetActiveDashboard')
);
const Feedback = React.lazy(() => import('erpcore/screens/Feedback'));
// My Account
const MyAccountPersonalDetails = React.lazy(() =>
    import('erpcore/screens/MyAccount/screens/MyAccountPersonalDetails')
);
// Accounting
const AccountingCategoriesExpenses = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Categories/screens/ExpenseCategories')
);
const AccountingCategoriesRevenues = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Categories/screens/RevenueCategories')
);
// Approvals
const ApprovalsListing = React.lazy(() =>
    import('erpcore/screens/Approvals/screens/ApprovalsListing')
);
const ApprovalsEdit = React.lazy(() => import('erpcore/screens/Approvals/screens/ApprovalsEdit'));
const AccountingInvoices = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Invoices/screens/InvoicesListing')
);
const AccountingInvoicesCreate = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Invoices/screens/InvoicesCreate')
);
const AccountingInvoicesEdit = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Invoices/screens/InvoicesEdit')
);
const AccountingExpenses = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Expenses/screens/ExpensesListing')
);
const AccountingExpensesCreate = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Expenses/screens/ExpensesCreate')
);
const AccountingExpensesEdit = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Expenses/screens/ExpensesEdit')
);
const AccountingRevenues = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Revenues/screens/RevenuesListing')
);
const AccountingRevenuesCreate = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Revenues/screens/RevenuesCreate')
);
const AccountingRevenuesEdit = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/Revenues/screens/RevenuesEdit')
);
// Company
const CompaniesListing = React.lazy(() =>
    import('erpcore/screens/Companies/screens/CompaniesListing')
);
const CompanyCreate = React.lazy(() => import('erpcore/screens/Companies/screens/CompanyCreate'));
const CompanyEdit = React.lazy(() => import('erpcore/screens/Companies/screens/CompanyEdit'));
const CompanyContacts = React.lazy(() =>
    import('erpcore/screens/Companies/screens/CompanyContacts')
);
const CompanyComments = React.lazy(() =>
    import('erpcore/screens/Companies/screens/CompanyComments')
);

const CompanyClientDashboard = React.lazy(() =>
    import('erpcore/screens/Companies/screens/CompanyClientDashboard')
);
// Contacts
const ContactsListing = React.lazy(() =>
    import('erpcore/screens/Contacts/screens/ContactsListing')
);
const ContactCreate = React.lazy(() => import('erpcore/screens/Contacts/screens/ContactCreate'));
const ContactEdit = React.lazy(() => import('erpcore/screens/Contacts/screens/ContactEdit'));
// Integrations
const Jira = React.lazy(() => import('erpcore/screens/Integrations/Jira'));
const JiraIssueSync = React.lazy(() =>
    import('erpcore/screens/Integrations/Jira/components/JiraIssueSync')
);
const SlackTimeOff = React.lazy(() => import('erpcore/screens/Integrations/Slack/screens/TimeOff'));
const SlackProps = React.lazy(() => import('erpcore/screens/Integrations/Slack/screens/Props'));
const Harvest = React.lazy(() => import('erpcore/screens/Integrations/Harvest'));
const Toggl = React.lazy(() => import('erpcore/screens/Integrations/Toggl'));
const Freshbooks = React.lazy(() => import('erpcore/screens/Integrations/Freshbooks'));
const QuickBooks = React.lazy(() => import('erpcore/screens/Integrations/QuickBooks'));
const Sage = React.lazy(() => import('erpcore/screens/Integrations/Sage'));
const SlackWorkAnniversary = React.lazy(() =>
    import('erpcore/screens/Integrations/Slack/screens/WorkAnniversary')
);
const SlackBirthday = React.lazy(() =>
    import('erpcore/screens/Integrations/Slack/screens/Birthday')
);
// Payroll reports
const PayrollReportsListing = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/PayrollReports/screens/PayrollReportsListing')
);
const PayrollReportCreate = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/PayrollReports/screens/PayrollReportCreate')
);
const PayrollReportEdit = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/PayrollReports/screens/PayrollReportEdit')
);
// Projects
const ProjectsListing = React.lazy(() => import('erpcore/screens/Projects/screens/Projects'));
const ProjectApprovalsPrivate = React.lazy(() =>
    import(
        'erpcore/screens/Projects/screens/ProjectManage/screens/ProjectApprovals/screens/ProjectApprovalsPrivate'
    )
);
const ProjectManage = React.lazy(() => import('erpcore/screens/Projects/screens/ProjectManage'));

// Props
const Props = React.lazy(() => import('erpcore/screens/Props'));
// Reports
const EmployeeRecordsReport = React.lazy(() => import('erpcore/screens/Reports/EmployeeRecords'));
const TimeLogsReport = React.lazy(() => import('erpcore/screens/Reports/TimeLogs'));
const UserReports = React.lazy(() => import('erpcore/screens/Reports/UserReports'));
const ProfitAndLoss = React.lazy(() => import('erpcore/screens/Reports/ProfitAndLoss'));
const ProfitabilityReports = React.lazy(() =>
    import('erpcore/screens/Reports/ProfitabilityReports')
);
const EfficiencyReports = React.lazy(() => import('erpcore/screens/Reports/EfficiencyReports'));
// Sales Orders
const BillingRequests = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/BillingRequests/screens/BillingRequestsListing')
);
const BillingRequestCreate = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/BillingRequests/screens/BillingRequestCreate')
);
const BillingRequestEdit = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/BillingRequests/screens/BillingRequestEdit')
);
const BillingRequestActivityLog = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/BillingRequests/screens/BillingRequestActivityLog')
);
const BillingRequestTimeLogs = React.lazy(() =>
    import('erpcore/screens/Accounting/screens/BillingRequests/screens/BillingRequestTimeLogs')
);
// Time logs
const TimeLogsListing = React.lazy(() =>
    import('erpcore/screens/TimeLogs/screens/TimeLogsListing')
);
const TimeLogCreate = React.lazy(() => import('erpcore/screens/TimeLogs/screens/TimeLogCreate'));
// Time Off
const TimeOffListing = React.lazy(() => import('erpcore/screens/TimeOff/screens/TimeOffListing'));
const TimeOffCreate = React.lazy(() => import('erpcore/screens/TimeOff/screens/TimeOffCreate'));
const TimeOffEdit = React.lazy(() => import('erpcore/screens/TimeOff/screens/TimeOffEdit'));
const TimeOffCalendar = React.lazy(() => import('erpcore/screens/TimeOff/screens/TimeOffCalendar'));
const TimeOffReport = React.lazy(() => import('erpcore/screens/TimeOff/screens/TimeOffReport'));
const TimeOffBalance = React.lazy(() => import('erpcore/screens/TimeOffBalance'));
// Users
const UsersListing = React.lazy(() => import('erpcore/screens/Users/screens/UsersListing'));
const UserCreate = React.lazy(() => import('erpcore/screens/Users/screens/UserCreate'));
const UserEdit = React.lazy(() => import('erpcore/screens/Users/screens/UserEdit'));
const UserEmploymentRecords = React.lazy(() =>
    import('erpcore/screens/Users/screens/UserEmploymentRecords')
);
const UserPermissions = React.lazy(() => import('erpcore/screens/Users/screens/UserPermissions'));
const UserLinkedAccounts = React.lazy(() =>
    import('erpcore/screens/Users/screens/UserLinkedAccounts')
);
//  Organization chart
const OrganizationChart = React.lazy(() => import('erpcore/screens/OrganizationChart'));
// Settings
const BundleTemplatesListing = React.lazy(() =>
    import('erpcore/screens/Settings/BundleTemplates/screens/BundleTemplatesListing')
);
const BundleTemplateEdit = React.lazy(() =>
    import('erpcore/screens/Settings/BundleTemplates/screens/BundleTemplateEdit')
);
const TimelineTemplates = React.lazy(() =>
    import('erpcore/screens/Settings/TimelineTemplates/screens/TimelineTemplatesListing')
);
const TimelineTemplateEdit = React.lazy(() =>
    import('erpcore/screens/Settings/TimelineTemplates/screens/TimelineTemplateEdit')
);
const Departments = React.lazy(() => import('erpcore/screens/Settings/Departments'));
const TimeOffTypes = React.lazy(() => import('erpcore/screens/Settings/TimeOffTypes'));
const TimeOffTracking = React.lazy(() => import('erpcore/screens/Settings/TimeOffTracking'));
const Divisions = React.lazy(() => import('erpcore/screens/Settings/Divisions'));
const EmployeeHourlyCost = React.lazy(() => import('erpcore/screens/Settings/EmployeeHourlyCost'));
const JobTitles = React.lazy(() => import('erpcore/screens/Settings/JobTitles'));
const Offices = React.lazy(() => import('erpcore/screens/Settings/Offices'));
const OrganizationGeneral = React.lazy(() =>
    import('erpcore/screens/Settings/Organization/screens/OrganizationGeneral')
);
const OrganizationBranding = React.lazy(() =>
    import('erpcore/screens/Settings/Organization/screens/OrganizationBranding')
);
const PaymentMethods = React.lazy(() => import('erpcore/screens/Settings/PaymentMethods'));
const PaymentTerms = React.lazy(() => import('erpcore/screens/Settings/PaymentTerms'));
const Permissions = React.lazy(() => import('erpcore/screens/Settings/Permissions'));
const PropsSettings = React.lazy(() => import('erpcore/screens/Settings/Props'));
const Roles = React.lazy(() => import('erpcore/screens/Settings/Roles/screens/Roles'));
const RoleCreate = React.lazy(() => import('erpcore/screens/Settings/Roles/screens/RoleCreate'));
const RoleEdit = React.lazy(() => import('erpcore/screens/Settings/Roles/screens/RoleEdit'));
const LeadStatuses = React.lazy(() =>
    import('erpcore/screens/Settings/LeadStatuses/screens/LeadStatuses')
);
const LeadStatusCreate = React.lazy(() =>
    import('erpcore/screens/Settings/LeadStatuses/screens/LeadStatusCreate')
);
const LeadStatusEdit = React.lazy(() =>
    import('erpcore/screens/Settings/LeadStatuses/screens/LeadStatusEdit')
);
const Tags = React.lazy(() => import('erpcore/screens/Settings/Tags'));
const WorkTypes = React.lazy(() => import('erpcore/screens/Settings/WorkTypes'));
const Impersonate = React.lazy(() => import('erpcore/screens/Impersonate'));
const ImpersonateStop = React.lazy(() => import('erpcore/screens/ImpersonateStop'));
const SelectTest = React.lazy(() => import('erpcore/screens/SelectTest'));
const DateTest = React.lazy(() => import('erpcore/screens/DateTest'));

const CapacityManagementV2 = React.lazy(() =>
    import('erpcore/screens/Scheduling/screens/CapacityManagementV2')
);
const CapacityManagement = React.lazy(() =>
    import('erpcore/screens/Scheduling/screens/CapacityManagement')
);
const ProjectAssignments = React.lazy(() =>
    import('erpcore/screens/Scheduling/screens/ProjectAssignments')
);

const BackRoutes = () => {
    const meData = useSelector(getMeData);
    const hasPermissionsAccessForEntities = getPermissionsAccessForEntities(meData);

    return (
        <LayoutManager slot="main" layoutType="merge">
            <Suspense fallback={<SuspenseLoader />}>
                <Routes>
                    <Route exact path="/reset-password" element={<ResetPasswordSignedIn />} />
                    <Route
                        exact
                        path="/reset-password/:token"
                        element={<ResetPasswordSignedIn />}
                    />
                    <Route key="Dashboard" path="/dashboard" exact element={<DashboardRouter />} />
                    <Route
                        key="Dashboards"
                        path="/analytics/dashboards"
                        exact
                        element={<Dashboards />}
                    />
                    {hasPermissionsAccessForEntities.projects && (
                        <Route
                            key="ProjectReviewDashboard"
                            path="/analytics/project-review-dashboard"
                            exact
                            element={<ProjectReviewDashboard />}
                        />
                    )}
                    <Route
                        key="Dashboard"
                        path="/analytics/personal-dashboard"
                        exact
                        element={<DashboardRouter />}
                    />
                    <Route
                        key="Dashboards"
                        path="/set-active-dashboard/:id"
                        exact
                        element={<SetActiveDashboard />}
                    />

                    {/* My Account */}
                    <Route
                        key="MyAccountPersonalDetails"
                        path="/my-account"
                        exact
                        element={<MyAccountPersonalDetails />}
                    />
                    <Route key="Feedback" path="/feedback" exact element={<Feedback />} />
                    {/* Approvals */}
                    {hasPermissionsAccessForEntities.projects && (
                        <Route
                            key="ApprovalsListing"
                            path="/approvals"
                            exact
                            element={<ApprovalsListing />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.projects && (
                        <Route
                            key="ApprovalsEdit"
                            path="/approvals/edit/:approvalID"
                            exact
                            element={<ApprovalsEdit />}
                        />
                    )}
                    {/* Users */}
                    {hasPermissionsAccessForEntities.users && (
                        <Route key="UsersListing" path="/users" exact element={<UsersListing />} />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                        <Route
                            key="UserCreate"
                            path="/users/create"
                            exact
                            element={<UserCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                        <Route
                            key="UserEdit"
                            path="/users/:id/edit/details"
                            exact
                            element={<UserEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                        <Route
                            key="UserEmploymentRecords"
                            path="/users/:id/edit/employment-records"
                            exact
                            element={<UserEmploymentRecords />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                        <Route
                            key="UserPermissions"
                            path="/users/:id/edit/permissions"
                            exact
                            element={<UserPermissions />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_USER', meData) && (
                        <Route
                            key="UserPermissions"
                            path="/users/:id/edit/linked-accounts"
                            exact
                            element={<UserLinkedAccounts />}
                        />
                    )}
                    {/* Integrations */}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="SlackTimeOff"
                            path="/integrations/slack/time-off"
                            exact
                            element={<SlackTimeOff />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="SlackProps"
                            path="/integrations/slack/props"
                            exact
                            element={<SlackProps />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="SlackBirthday"
                            path="/integrations/slack/birthday"
                            exact
                            element={<SlackBirthday />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="SlackWorkAnniversary"
                            path="/integrations/slack/work-anniversary"
                            exact
                            element={<SlackWorkAnniversary />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <>
                            <Route
                                key="JiraIntegration"
                                path="/integrations/jira"
                                exact
                                element={<Jira />}
                            />
                            <Route
                                key="JiraIssueSync"
                                path="/integrations/jira/issue-sync"
                                exact
                                element={<JiraIssueSync />}
                            />
                        </>
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="HarvestIntegration"
                            path="/integrations/harvest"
                            exact
                            element={<Harvest />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="TogglIntegration"
                            path="/integrations/toggl"
                            exact
                            element={<Toggl />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="FreshbooksIntegration"
                            path="/integrations/freshbooks"
                            exact
                            element={<Freshbooks />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="QuickBooksIntegration"
                            path="/integrations/quickbooks"
                            exact
                            element={<QuickBooks />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CORE_BUNDLE_OPTION', meData) && (
                        <Route
                            key="SageIntegration"
                            path="/integrations/sage"
                            exact
                            element={<Sage />}
                        />
                    )}
                    {/* Settings */}
                    {hasPermission('CAN_MANAGE_COMMON_BUNDLETEMPLATE', meData) && (
                        <Route
                            key="PaymentTerms"
                            path="/settings/bundle-templates"
                            exact
                            element={<BundleTemplatesListing />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_BUNDLETEMPLATE', meData) && (
                        <Route
                            key="PaymentTerms"
                            path="/settings/bundle-templates/:id/edit"
                            exact
                            element={<BundleTemplateEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROJECTSTAGETEMPLATE', meData) && (
                        <>
                            <Route
                                key="PaymentTerms"
                                path="/settings/timeline-templates/"
                                exact
                                element={<TimelineTemplates />}
                            />
                            <Route
                                key="PaymentTerms"
                                path="/settings/timeline-templates/:id/edit"
                                exact
                                element={<TimelineTemplateEdit />}
                            />
                        </>
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_DEPARTMENT', meData) && (
                        <Route
                            key="Departments"
                            path="/settings/departments"
                            exact
                            element={<Departments />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData) && (
                        <Route
                            key="TimeOffTypes"
                            path="/settings/time-off"
                            exact
                            element={<TimeOffTypes />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMEOFF', meData) && (
                        <Route
                            key="TimeOffTracking"
                            path="/settings/time-off-tracking"
                            exact
                            element={<TimeOffTracking />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TAGS_PROJECTTAG', meData) && (
                        <Route key="TagsProject" path="/settings/tags" exact element={<Tags />} />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROJECT_LEADSTATUS', meData) && (
                        <Route
                            key="LeadStatuses"
                            path="/settings/lead-statuses"
                            exact
                            element={<LeadStatuses />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROJECT_LEADSTATUS', meData) && (
                        <Route
                            key="LeadStatusesCreate"
                            path="/settings/lead-statuses/create"
                            exact
                            element={<LeadStatusCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROJECT_LEADSTATUS', meData) && (
                        <Route
                            key="LeadStatusEdit"
                            path="/settings/lead-statuses/:id/edit"
                            exact
                            element={<LeadStatusEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_DIVISION', meData) && (
                        <Route
                            key="Divisions"
                            path="/settings/divisions"
                            exact
                            element={<Divisions />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_HOURLYCOST', meData) && (
                        <Route
                            key="EmployeeHourlyCost"
                            path="/analytics/employee-hourly-cost"
                            exact
                            element={<EmployeeHourlyCost />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_JOBTITLE', meData) && (
                        <Route
                            key="JobTitles"
                            path="/settings/job-titles"
                            exact
                            element={<JobTitles />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_OFFICE', meData) && (
                        <Route key="Offices" path="/settings/offices" exact element={<Offices />} />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                        <Route
                            key="OrganizationGeneral"
                            path="/settings/organization/general"
                            exact
                            element={<OrganizationGeneral />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_ORGANIZATION', meData) && (
                        <Route
                            key="OrganizationBranding"
                            path="/settings/organization/branding"
                            exact
                            element={<OrganizationBranding />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_WORKTYPE', meData) && (
                        <Route
                            key="WorkTypes"
                            path="/settings/work-types"
                            exact
                            element={<WorkTypes />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Route key="Roles" path="/settings/roles" exact element={<Roles />} />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Route
                            key="RoleCreate"
                            path="/settings/roles/create"
                            exact
                            element={<RoleCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_ROLE', meData) && (
                        <Route
                            key="RoleEdit"
                            path="/settings/roles/:id/edit"
                            exact
                            element={<RoleEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_SECURITY_BUNDLE_PERMISSION', meData) && (
                        <Route
                            key="Permissions"
                            path="/settings/permissions"
                            exact
                            element={<Permissions />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROP_TYPE', meData) && (
                        <Route
                            key="PropsSettings"
                            path="/settings/props"
                            exact
                            element={<PropsSettings />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PAYMENTMETHOD', meData) && (
                        <Route
                            key="PaymentMethods"
                            path="/settings/payment-methods"
                            exact
                            element={<PaymentMethods />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PAYMENTTERM', meData) && (
                        <Route
                            key="PaymentTerms"
                            path="/settings/payment-terms"
                            exact
                            element={<PaymentTerms />}
                        />
                    )}
                    {/* Companies */}
                    {hasPermissionsAccessForEntities.companies && (
                        <Route
                            key="CompaniesListing"
                            path="/companies"
                            exact
                            element={<CompaniesListing />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                        <Route
                            key="CompanyCreate"
                            path="/companies/create"
                            exact
                            element={<CompanyCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                        <Route
                            key="CompanyEdit"
                            path="/companies/:id/edit/general-info"
                            exact
                            element={<CompanyEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                        <Route
                            key="CompanyContacts"
                            path="/companies/:id/edit/contacts"
                            exact
                            element={<CompanyContacts />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                        <Route
                            key="CompanyComments"
                            path="/companies/:id/edit/comments"
                            exact
                            element={<CompanyComments />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMPANY_BUNDLE_COMPANY', meData) && (
                        <Route
                            key="CompanyClientDashboard"
                            path="/companies/:id/edit/client-dashboard"
                            exact
                            element={<CompanyClientDashboard />}
                        />
                    )}
                    {/* Contacts */}
                    {hasPermissionsAccessForEntities.contacts && (
                        <Route
                            key="Contacts"
                            path="/contacts"
                            exact
                            element={<ContactsListing />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData) && (
                        <Route
                            key="ContactCreate"
                            path="/contacts/create"
                            exact
                            element={<ContactCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_CONTACT_BUNDLE_CONTACT', meData) && (
                        <Route
                            key="ContactEdit"
                            path="/contacts/:id/edit"
                            exact
                            element={<ContactEdit />}
                        />
                    )}
                    {/* Projects */}
                    {hasPermissionsAccessForEntities.projects && (
                        <Route
                            key="Projects"
                            path="/projects"
                            exact
                            element={<ProjectsListing />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_PROJECT', meData) && (
                        <Route
                            key="ProjectManage"
                            path="/projects/:id/*"
                            element={<ProjectManage />}
                            index
                        />
                    )}

                    <Route exact path="/approvals/:hash" element={<ProjectApprovalsPrivate />} />
                    {/* Props */}
                    {hasPermission('CAN_MANAGE_COMMON_PROP', meData) && (
                        <Route key="Props" path="/props" exact element={<Props />} />
                    )}
                    {/* Time logs */}
                    {hasPermissionsAccessForEntities.timeLogs && (
                        <Route
                            key="TimeLogsListing"
                            path="/time-logs"
                            exact
                            element={<TimeLogsListing />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.timeLogs && (
                        <Route
                            key="TimeLogCreate"
                            path="/time-logs/create"
                            exact
                            element={<TimeLogCreate />}
                        />
                    )}
                    {/* Time Off */}
                    {(hasPermissionsAccessForEntities.timeOff ||
                        hasPermissionsAccessForEntities.timeOffHR) && (
                        <Route
                            key="TimeOffListing"
                            path="/time-off"
                            exact
                            element={<TimeOffListing />}
                        />
                    )}
                    {(hasPermissionsAccessForEntities.timeOff ||
                        hasPermissionsAccessForEntities.timeOffHR) && (
                        <Route
                            key="TimeOffCalendar"
                            path="/time-off/calendar"
                            exact
                            element={<TimeOffCalendar />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.timeOffHR && (
                        <Route
                            key="TimeOffReport"
                            path="/time-off/report"
                            exact
                            element={<TimeOffReport />}
                        />
                    )}
                    {(hasPermissionsAccessForEntities.timeOff ||
                        hasPermissionsAccessForEntities.timeOffHR) && (
                        <Route
                            key="TimeOffCreate"
                            path="/time-off/create"
                            exact
                            element={<TimeOffCreate />}
                        />
                    )}
                    {(hasPermissionsAccessForEntities.timeOff ||
                        hasPermissionsAccessForEntities.timeOffHR) && (
                        <Route
                            key="TimeOffEdit"
                            path="/time-off/:id/edit"
                            exact
                            element={<TimeOffEdit />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.timeOffBalance && (
                        <Route
                            key="TimeOffBalance"
                            path="/time-off-balance/"
                            exact
                            element={<TimeOffBalance />}
                        />
                    )}
                    {/* Reports */}
                    {hasPermission('CAN_MANAGE_COMMON_EMPLOYMENTRECORD', meData) && (
                        <Route
                            key="EmployeeRecordsReport"
                            path="/analytics/employee-records"
                            exact
                            element={<EmployeeRecordsReport />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_COMMON_TIMELOG', meData) && (
                        <Route
                            key="TimeLogsReport"
                            path="/analytics/time-logs"
                            exact
                            element={<TimeLogsReport />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.timeLogs && (
                        <Route
                            key="UserReports"
                            path="/analytics/employee-report"
                            exact
                            element={<UserReports />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REPORT_EXPENSE', meData) &&
                        hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REPORT_REVENUE', meData) && (
                            <Route
                                key="ProfitAndLoss"
                                path="/analytics/profit-and-loss"
                                exact
                                element={<ProfitAndLoss />}
                            />
                        )}

                    {hasPermission('CAN_MANAGE_PROFITABILITY_REPORT', meData) && (
                        <Route
                            key="Profitability"
                            path="/analytics/profitability"
                            exact
                            element={<ProfitabilityReports />}
                        />
                    )}

                    {hasPermission('CAN_MANAGE_EFFICIENCY_REPORT', meData) && (
                        <Route
                            key="Efficiency"
                            path="/analytics/efficiency"
                            exact
                            element={<EfficiencyReports />}
                        />
                    )}

                    {/* Accounting */}
                    {/* Billing Requests */}
                    {hasPermissionsAccessForEntities.billingRequests && (
                        <Route
                            key="BillingRequests"
                            path="/accounting/billing-requests"
                            exact
                            element={<BillingRequests />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.billingRequests && (
                        <Route
                            key="BillingRequestCreate"
                            path="/accounting/billing-requests/create"
                            exact
                            element={<BillingRequestCreate />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.billingRequests && (
                        <Route
                            key="BillingRequestEdit"
                            path="/accounting/billing-requests/:id/edit/general-info"
                            exact
                            element={<BillingRequestEdit />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.billingRequests && (
                        <Route
                            key="BillingRequestTimeLogs"
                            path="/accounting/billing-requests/:id/edit/time-logs"
                            exact
                            element={<BillingRequestTimeLogs />}
                        />
                    )}
                    {hasPermissionsAccessForEntities.billingRequests && (
                        <Route
                            key="BillingRequestActivityLog"
                            path="/accounting/billing-requests/:id/edit/activity-log"
                            exact
                            element={<BillingRequestActivityLog />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_CATEGORY', meData) && (
                        <Route
                            key="AccountingCategoriesExpenses"
                            path="/accounting/categories/expense"
                            exact
                            element={<AccountingCategoriesExpenses />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_CATEGORY', meData) && (
                        <Route
                            key="AccountingCategoriesRevenues"
                            path="/accounting/categories/revenue"
                            exact
                            element={<AccountingCategoriesRevenues />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_CATEGORY', meData) && (
                        <Route
                            key="/accounting/categories/navigate"
                            path="/accounting/categories"
                            exact
                            element={<Navigate to="/accounting/categories/expense" />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_INVOICE', meData) && (
                        <Route
                            key="AccountingInvoices"
                            path="/accounting/invoices"
                            exact
                            element={<AccountingInvoices />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_INVOICE', meData) && (
                        <Route
                            key="AccountingInvoicesCreate"
                            path="/accounting/invoices/create"
                            exact
                            element={<AccountingInvoicesCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_INVOICE', meData) && (
                        <Route
                            key="AccountingInvoicesEdit"
                            path="/accounting/invoices/:id/edit"
                            exact
                            element={<AccountingInvoicesEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_EXPENSE', meData) && (
                        <Route
                            key="AccountingExpenses"
                            path="/accounting/expenses"
                            exact
                            element={<AccountingExpenses />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_EXPENSE', meData) && (
                        <Route
                            key="AccountingExpensesCreate"
                            path="/accounting/expenses/create"
                            exact
                            element={<AccountingExpensesCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_EXPENSE', meData) && (
                        <Route
                            key="AccountingExpensesEdit"
                            path="/accounting/expenses/:id/edit"
                            exact
                            element={<AccountingExpensesEdit />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REVENUE', meData) && (
                        <Route
                            key="AccountingRevenues"
                            path="/accounting/revenues"
                            exact
                            element={<AccountingRevenues />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REVENUE', meData) && (
                        <Route
                            key="AccountingRevenuesCreate"
                            path="/accounting/revenues/create"
                            exact
                            element={<AccountingRevenuesCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_ACCOUNTING_BUNDLE_REVENUE', meData) && (
                        <Route
                            key="AccountingRevenuesEdit"
                            path="/accounting/revenues/:id/edit"
                            exact
                            element={<AccountingRevenuesEdit />}
                        />
                    )}
                    {/* Accounting - Payroll Reports */}
                    {hasPermission('CAN_MANAGE_PAYROLL_REPORT_PAYROLLREPORT', meData) && (
                        <Route
                            key="PayrollReportsListing"
                            path="/accounting/payroll-reports"
                            exact
                            element={<PayrollReportsListing />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_PAYROLL_REPORT_PAYROLLREPORT', meData) && (
                        <Route
                            key="PayrollReportCreate"
                            path="/accounting/payroll-reports/create"
                            exact
                            element={<PayrollReportCreate />}
                        />
                    )}
                    {hasPermission('CAN_MANAGE_PAYROLL_REPORT_PAYROLLREPORT', meData) && (
                        <Route
                            key="PayrollReportEdit"
                            path="/accounting/payroll-reports/:id/edit"
                            exact
                            element={<PayrollReportEdit />}
                        />
                    )}
                    {(hasPermission('CAN_MANAGE_COMMON_CAPACITYSCHEDULE', meData) ||
                        hasPermission('CAN_VIEW_OWN_SCHEDULE', meData)) && (
                        <>
                            <Route
                                key="Scheduling"
                                path="/planning/assignments"
                                element={<ProjectAssignments />}
                            />
                            <Route
                                key="CapacityManagement"
                                path="/planning/capacity"
                                element={<CapacityManagementV2 />}
                            />
                            <Route
                                key="CapacityManagement"
                                path="/planning/capacity/old"
                                element={<CapacityManagement />}
                            />
                        </>
                    )}

                    <Route
                        key="OrganizationChart"
                        path="/organization-chart"
                        exact
                        element={<OrganizationChart />}
                    />

                    {!['production', 'staging'].includes(process.env.REACT_APP_ENV) && (
                        <Route
                            key="SelectTest"
                            path="/select-test"
                            exact
                            element={<SelectTest />}
                        />
                    )}

                    {!['production', 'staging'].includes(process.env.REACT_APP_ENV) && (
                        <Route key="DateTest" path="/date-test" exact element={<DateTest />} />
                    )}

                    <Route
                        key="impersonate"
                        path="/impersonate/:id"
                        exact
                        element={<Impersonate />}
                    />
                    <Route
                        key="stop-impersonate"
                        path="/stop-impersonate"
                        exact
                        element={<ImpersonateStop />}
                    />
                    <Route
                        key="impersonate-stop"
                        path="/impersonate-stop"
                        exact
                        element={<ImpersonateStop />}
                    />
                    <Route exact path="/" element={<Navigate exact from="/" to="/dashboard" />} />
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </Suspense>
        </LayoutManager>
    );
};

BackRoutes.defaultProps = {};

BackRoutes.propTypes = {};

export default BackRoutes;
